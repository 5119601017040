import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {BrowserRouter as Router} from "react-router-dom";
import {ModelDetailsContextProvider} from "./context/ModelDetailsContext";
import "semantic-ui-css/semantic.min.css";

ReactDOM.render(
    <Router>
        <ModelDetailsContextProvider>
            <App/>
        </ModelDetailsContextProvider>
    </Router>
    , document.getElementById("root"));
