import * as home from './home-admin/route';

import * as searchModels from './models/search-models/route';
import * as createModel from './models/create-models/route';
import * as detailsModel from './models/details-model/route';
import * as editModel from './models/edit-model/route';
import * as searchArchiveModels from './models/search-archive-models/route'

import * as searchSessions from './sessions/search-sessions/route';
import * as createSession from './sessions/create-session/route';
import * as detailsSession from './sessions/details-session/route';
import * as lastSessions from './sessions/last-sessions/route';
import * as editSession from './sessions/edit-session/route';

import * as calendarPage from './calendar/route';

import * as allTasks from './tasks/all-tasks/route';
import * as createTask from './tasks/create-task/route';
import * as detailsTask from './tasks/details-task/route';
import * as editTask from './tasks/edit-task/route';

export default [
    home,
    //Models
    detailsModel,
    createModel,
    editModel,
    searchModels,
    searchArchiveModels,
    //Sessions
    detailsSession,
    createSession,
    editSession,
    searchSessions,
    lastSessions,
    //Tasks
    createTask,
    editTask,
    detailsTask,
    allTasks,
    //Other
    calendarPage,
]