import React from 'react';
import {Container} from "semantic-ui-react";
import {Route, Switch} from "react-router-dom";
import adminRoutes from "../routes-admin";

export const AdminDashboard = () => {
    return <Container>
        <Switch>
            {adminRoutes.map((props, index) => {
                return <Route key={index} {...props} />;
            })}
            {/*<Route path="/sessions/:id/edit" component={SessionForm} />*/}
            {/*<Route path="/models/:id/edit" component={ModelForm} />*/}
        </Switch>
    </Container>

};
