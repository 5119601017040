import {db} from "../firebase";
import {
    collection,
    query,
    limit,
    orderBy,
    endAt,
    startAt,
    where,
    getDocs as getDocsFromDb
} from "firebase/firestore";

export const getDocs = async (collectionName, requestedOrderBy, requestedQuery, showMore) => {

    const q = query(
        collection(db, collectionName),
        orderBy(requestedOrderBy),
        startAt(requestedQuery),
        endAt(requestedQuery + "\uf8ff"),
        limit(1 * showMore)
    );

    const response = await getDocsFromDb(q);
    return response.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
    }));
};

/**
 * Retrieves documents from a Cloud Firestore collection using a where clause.
 *
 * @param {string} collectionName - The name of the collection to query.
 * @param {string} field - The field to filter on.
 * @param {string} operator - The operator to use for the filter (e.g. "==", "<", ">", etc.).
 * @param {any} value - The value to filter the field by.
 * @returns {Array} An array of documents from the collection that match the where clause.
 */

export const getDocsWhere = async (collectionName, field, operator, value) => {

    const q = query(
        collection(db, collectionName),
        where(field, operator, value)
    );

    const response = await getDocsFromDb(q);
    return response.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
    }));
};

/**
 * Retrieves documents from a Cloud Firestore collection using multiple where clauses.
 *
 * @param {string} collectionName - The name of the collection to query.
 * @param {Array} conditions - An array of objects representing the where conditions.
 * @param {string} conditions[].field - The field to filter on.
 * @param {string} conditions[].operator - The operator to use for the filter (e.g., "==", "<", ">", etc.).
 * @param {any} conditions[].value - The value to filter the field by.
 * @returns {Array} An array of documents from the collection that match the where clauses.
 */

export const getDocsWhereMultiple = async (collectionName, conditions) => {
    // Build the query dynamically based on conditions
    let q = collection(db, collectionName);

    conditions.forEach(({field, operator, value}) => {
        q = query(q, where(field, operator, value));
    });

    // Execute the query
    const response = await getDocsFromDb(q);

    // Map the documents to an array
    return response.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
    }));
};
