const firebaseConfig = {
  apiKey: "AIzaSyB4SYExsqIlAVxgbXRNLj955wZ2YWIskbQ",
  authDomain: "image-models.firebaseapp.com",
  databaseURL: "https://image-models.firebaseio.com",
  projectId: "image-models",
  storageBucket: "image-models.appspot.com",
  messagingSenderId: "391808717098",
  appId: "1:391808717098:web:5b25c3f712d0ae43a70e53",
};

export default firebaseConfig;
