import React from "react";
import {Button, Dropdown, Menu} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {signOut} from "firebase/auth";
import {auth} from "../../firebase";
import {path as pathToAllTasks} from '../../tasks/all-tasks/route';
import {path as pathToCalendar} from '../../calendar/route';

export const Navbar = ({user}) => {
    return (
        <Menu className="noprint" color="blue" inverted pointing>
            {user ? (
                <>
                    <Menu.Item>שלום, {user.displayName}!</Menu.Item>
                    <Menu.Item as={Link} to="/admin">
                        מסך הבית
                    </Menu.Item>
                    <Menu.Item as={Link} to={pathToCalendar}>
                        יומן
                    </Menu.Item>
                    <Menu.Item as={Link} to={pathToAllTasks}>
                        משימות
                    </Menu.Item>
                    <Dropdown item text=" מיוצגים ">
                        <Dropdown.Menu>
                            <Dropdown.Item
                                as={Link}
                                to="/admin/create-model"
                                style={{textAlign: "center"}}
                            >
                                צור מיוצג חדש
                            </Dropdown.Item>
                            <Dropdown.Item
                                as={Link}
                                to="/admin/models"
                                style={{textAlign: "center"}}
                            >
                                חפש מיוצגים פעילים
                            </Dropdown.Item>
                            <Dropdown.Item
                                as={Link}
                                to="/admin/models-archive"
                                style={{textAlign: "center"}}
                            >
                                חפש מיוצגים בארכיון
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown item text=" ימי צילום ">
                        <Dropdown.Menu>
                            <Dropdown.Item
                                as={Link}
                                to="/admin/create-session"
                                style={{textAlign: "center"}}
                            >
                                צור יום צילום חדש
                            </Dropdown.Item>
                            <Dropdown.Item
                                as={Link}
                                to="/admin/sessions"
                                style={{textAlign: "center"}}
                            >
                                חפש ימי צילום
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Menu.Item position="left">
                        <Button inverted onClick={() => signOut(auth)}>
                            התנתק
                        </Button>
                    </Menu.Item>
                </>
            ) : <Menu.Item as={Link} position="left" to="/login">
                התחברות
            </Menu.Item>}
        </Menu>
    );
};
