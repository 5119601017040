import React from 'react';
import {Route, Switch} from "react-router-dom";
import {Container} from "semantic-ui-react";
import guestRoutes from "../routes-guest";


export const GustDashboard = () => {
    return <Container>
        <Switch>
            {guestRoutes.map((props, index) => {
                return <Route key={index} {...props} />;
            })}
        </Switch>
    </Container>

};


